<template>
  <section id="classroom">
    <b-row class="mb-2">
      <!-- Navigation Tab -->
      <b-col lg="12">
        <b-tabs
          pills
          class="navigation-tabs mt-1 mt-md-0"
          nav-class="mb-0"
        >
          <template #tabs-start>
            <b-nav-item
              v-for="(navtab, index) in navTabs"
              :key="index"
              :active="currentTab === index"
              class="font-weight-bold"
              @click="changeNavTab(index)"
            >
              <span>{{ navtab }}</span>
            </b-nav-item>
          </template>
        </b-tabs>
      </b-col>
    </b-row>

    <!-- Loading Classroom List-->
    <b-row v-if="isLoading">
      <b-col class="d-flex justify-content-center mt-4">
        <div class="text-center mt-4">
          <b-spinner
            variant="primary"
            class="mr-1"
          />
          <p class="mt-1">
            Memuat Daftar Pelatihan
          </p>
        </div>
      </b-col>
    </b-row>

    <!-- Classroom List -->
    <b-row v-if="!isLoading && trainings.length > 0">
      <b-col
        v-for="classroom in trainings"
        :key="classroom.id"
        sm="6"
        md="6"
        lg="4"
        xl="4"
      >
        <class-card
          :is-joined="classroom.join_status && classroom.join_status.status == 'Diterima' ? true : false"
          :is-teacher="false"
          :classroom-data="classroom"
          @trigger-modal="triggerModal"
        />
      </b-col>

      <!-- Pagination -->
      <b-col
        sm="12"
        class="d-flex justify-content-end"
      >
        <b-pagination
          v-model="filter.current_page"
          :total-rows="filter.total"
          :per-page="filter.per_page"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          @change="handlePageChange"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>

    <!-- Classroom Empty State -->
    <b-row v-if="!isLoading && trainings.length === 0">
      <b-col class="d-flex justify-content-center mt-4">
        <div class="text-center pt-2">
          <b-img
            class="illustration-small"
            fluid
            :src="emptyState"
          />
          <p class="mb-2">
            Pelatihan tidak ditemukan, Hubungi Admin untuk menambahkan pelatihan
          </p>
        </div>
      </b-col>
    </b-row>

  </section>
</template>

<script>
// *Utils
import store from '@/store'
import { checkAuthorizeRole } from '@/auth/utils'

// *Components
import {
  BRow,
  BCol,
  BTabs,
  BNavItem,
  BSpinner,
  BButton,
  BImg,
  BPagination,
} from 'bootstrap-vue'
import ClassCard from '@core/layouts/components/card/class-card/ClassCard.vue'
import {
  reqGetTrainings
} from '@/api/user/training'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BNavItem,
    ClassCard,
    BSpinner,
    BButton,
    BImg,
    BPagination,
  },
  data() {
    return {
      // State
      isLoading: true,
      currentTab: 0,
      navTabs: ['Semua Pelatihan', 'Sedang Diikuti', 'Sudah Selesai'],
      emptyState: require('@/assets/images/illustration/empty-classroom-state.svg'),

      // Data
      trainings: [],
      selectedTraining: {},

      // Filter
      filter: {
        search: '',
        page: 1,
        per_page: 9,
        joined: 0,
        finished: 0,
      },
    }
  },
  computed: {
    searchQuery() {
      return store.state.search.searchQuery
    },
  },
  watch: {
    searchQuery(value) {
      this.filter.search = value
      this.handlePageChange(1)
    },
  },
  async created() {
    this.filter.search = this.searchQuery

    // Get taken classroom
    await this.getTrainings()
  },
  methods: {
    triggerModal(subjectData) {
      this.selectedTraining = subjectData
      this.$bvModal.show('enroll-modal')
    },
    checkAuthorizeRole,

    // fetch content by nav tab
    async getTrainings() {
      this.isLoading = true
      await reqGetTrainings(this.filter).then(response => {
        this.isLoading = false
        this.trainings = response.data.data.data

        this.filter.total = response.data.data.total
        this.filter.per_page = response.data.data.per_page
        this.filter.current_page = response.data.data.current_page
      }).catch(() => {
        this.isLoading = false
      })
    },

    // handle nav tab
    changeNavTab(tabIndex) {
      this.currentTab = tabIndex
      if (tabIndex == 0) {
        this.filter.joined = 0
        this.filter.finished = 0
      } else if (tabIndex == 1) {
        this.filter.joined = 1
        this.filter.finished = 0
      } else if (tabIndex == 2) {
        this.filter.joined = 0
        this.filter.finished = 1
      }
      this.handlePageChange(1)
    },

    handlePageChange(page) {
      this.filter.page = page
      this.getTrainings()
    },

    // breakdown object if too long
    decomposeObject(object) {
      return JSON.parse(JSON.stringify(object))
    },
  },
}
</script>

<style lang="scss">
.small-gap-right {
  margin-right: 8px;
}

.subject-icon {
  font-size: 2rem;
}

#enroll-modal {
  .modal-body {
    padding: 1.5rem 2rem;
  }

  .modal-footer {
    display: none !important;
  }
}

.illustration-small {
  height: 300px;
  object-fit: cover;
}
</style>
