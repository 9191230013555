<template>
  <div>
    <b-card
      class="cursor-pointer"
      :img-src="
        classroomData.image
          ? classroomData.image
          : require('@/assets/images/slider/04.jpg')
      "
      img-alt="classroom image"
      img-top
      @click="
        $router.push({
          name: checkAuthorizeRole('trainings.show'),
          params: {
            trainingId: classroomData.id,
          },
        })
      "
    >
      <div class="class-wrapper">
        <b-media class="w-100" no-body>
          <b-media-aside class="mr-1">
            <b-avatar variant="light-primary" icon="">
              <i :class="'ti ti-school'" class="font-medium-2 ti" />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="classroom-title-box">
            <span class="d-block classroom-title">
              {{ pascalCase(classroomData.title) }}
            </span>
            <span class="font-small-2 text-muted">
              {{ classroomData.start_at | moment("Do MMM YYYY") }} -
              {{ classroomData.end_at | moment("Do MMM YYYY") }}
            </span>
          </b-media-body>
        </b-media>
      </div>

      <div class="w-100 overflow-hidden">
        <b-media class="w-100" no-body>
          <b-media-aside class="mr-1">
            <b-avatar
              size="35"
              :src="classroomData.trainer.avatar"
              :text="avatarText(classroomData.trainer.name)"
              variant="light-primary"
            />
          </b-media-aside>
          <b-media-body class="training-info">
            <div
              :class="isJoined ? 'trainer-info-box-joined' : 'trainer-info-box'"
            >
              <div class="trainer-info">
                <small class="ellipsis font-weight-bold d-block">
                  {{ classroomData.trainer.name }}
                </small>
                <small class="ellipsis d-block text-muted">
                  {{ classroomData.trainer.email }}
                </small>
              </div>
            </div>
            <div class="training-enter" v-if="isJoined">
              <button title="Anda terdaftar di pelatihan ini" class="btn btn-success p-0" ><i class="bi bi-check" style="font-size: 21px"></i></button>
            </div>
          </b-media-body>
        </b-media>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BButton,
  BMedia,
  BMediaAside,
  BMediaBody,
  BProgress,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import { avatarText, pascalCase } from "@core/utils/filter";
import { checkAuthorizeRole } from "@/auth/utils";

export default {
  components: {
    BAvatar,
    BCard,
    BButton,
    BMedia,
    BMediaAside,
    BMediaBody,
    BProgress,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    isJoined: {
      type: Boolean,
      required: true,
    },
    classroomData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isTeacher: {
      type: Boolean,
      required: false,
    },
  },
  methods: {},
  setup() {
    return {
      avatarText,
      checkAuthorizeRole,
      pascalCase,
    };
  },
};
</script>

<style lang="scss" scoped>
.class-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
}

.progress-wrapper {
  width: 50%;
  float: right;
  align-self: start;
  margin-top: 5px;
}

.max-width-flexible {
  max-width: calc(100%);
}

@media screen and (max-width: 1080px) {
  .max-width-flexible {
    max-width: calc(70%) !important;
  }
}

.d-block {
  display: block;
}

.disable-hover:hover,
.disable-hover:focus {
  background-color: inherit !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-img-top {
  height: 200px;
  object-fit: cover;
}

.small-gap-right {
  margin-right: 8px;
}

.training-info {
  flex: 1;
  max-width: calc(100% - 50px);
  display: flex;
  justify-content: space-between;
}
.trainer-info-box {
  flex: 1;
  max-width: calc(100% - 95px);
}
.trainer-info-box-joined {
  flex: 1;
  max-width: calc(100% - 70px);
}
.trainer-info {
  max-width: 100%;
}
.training-enter {
  margin-left: auto;
  overflow: hidden;
}
.training-join {
  margin-left: auto;
  overflow: hidden;
}
.classroom-title-box {
  max-width: calc(100% - 40px);
}
.classroom-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 900 !important;
}
</style>
