import axios from '@/libs/axios'

export function reqGetTrainings(payload) {
  return axios.get('api/trainings', { params: payload })
}
export function reqGetJoinedTrainings(payload) {
  return axios.get('api/trainings/joined', { params: payload })
}
export function reqGetTraining(id) {
  return axios.get(`api/trainings/${id}`)
}
export function reqJoinTraining(id, payload) {
  return axios.post(`api/trainings/${id}/request-join`, payload)
}
export function reqGetMyTrainingReview(id) {
  return axios.get(`api/trainings/${id}/my-review`)
}
export function reqUpdateMyTrainingReview(id, data) {
  return axios.patch(`api/trainings/${id}/my-review`, data)
}
