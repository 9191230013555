var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"cursor-pointer",attrs:{"img-src":_vm.classroomData.image
        ? _vm.classroomData.image
        : require('@/assets/images/slider/04.jpg'),"img-alt":"classroom image","img-top":""},on:{"click":function($event){_vm.$router.push({
        name: _vm.checkAuthorizeRole('trainings.show'),
        params: {
          trainingId: _vm.classroomData.id,
        },
      })}}},[_c('div',{staticClass:"class-wrapper"},[_c('b-media',{staticClass:"w-100",attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-1"},[_c('b-avatar',{attrs:{"variant":"light-primary","icon":""}},[_c('i',{staticClass:"font-medium-2 ti",class:'ti ti-school'})])],1),_c('b-media-body',{staticClass:"classroom-title-box"},[_c('span',{staticClass:"d-block classroom-title"},[_vm._v(" "+_vm._s(_vm.pascalCase(_vm.classroomData.title))+" ")]),_c('span',{staticClass:"font-small-2 text-muted"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.classroomData.start_at,"Do MMM YYYY"))+" - "+_vm._s(_vm._f("moment")(_vm.classroomData.end_at,"Do MMM YYYY"))+" ")])])],1)],1),_c('div',{staticClass:"w-100 overflow-hidden"},[_c('b-media',{staticClass:"w-100",attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-1"},[_c('b-avatar',{attrs:{"size":"35","src":_vm.classroomData.trainer.avatar,"text":_vm.avatarText(_vm.classroomData.trainer.name),"variant":"light-primary"}})],1),_c('b-media-body',{staticClass:"training-info"},[_c('div',{class:_vm.isJoined ? 'trainer-info-box-joined' : 'trainer-info-box'},[_c('div',{staticClass:"trainer-info"},[_c('small',{staticClass:"ellipsis font-weight-bold d-block"},[_vm._v(" "+_vm._s(_vm.classroomData.trainer.name)+" ")]),_c('small',{staticClass:"ellipsis d-block text-muted"},[_vm._v(" "+_vm._s(_vm.classroomData.trainer.email)+" ")])])]),(_vm.isJoined)?_c('div',{staticClass:"training-enter"},[_c('button',{staticClass:"btn btn-success p-0",attrs:{"title":"Anda terdaftar di pelatihan ini"}},[_c('i',{staticClass:"bi bi-check",staticStyle:{"font-size":"21px"}})])]):_vm._e()])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }